import { usePersistentStorage } from '@/hooks/usePersistentStorage';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useFlag } from '@/features/unleash';
import { checkExperimentEnabled } from './utils';
import type { AccessInfo } from './utils';

const ACCESS_INFO_STORAGE_KEY = 'access_info';

const DEFAULT_ACCESS_INFO = {
  accessCount: 0,
  lastShownAt: 0,
};

export const useFrequencyAccessExperiment = () => {
  const { variant } = useFlag({ name: 'mono-upgrades-modal-experiment' });

  const { get, set } = usePersistentStorage();

  const [accessInfo, setAccessInfo] = useState<AccessInfo>(DEFAULT_ACCESS_INFO);

  useEffect(() => {
    const handleAccessInfo = async () => {
      try {
        const accessInfoStr = await get(ACCESS_INFO_STORAGE_KEY);
        const currentAccessInfo = accessInfoStr
          ? JSON.parse(accessInfoStr)
          : DEFAULT_ACCESS_INFO;

        const newAccessInfo = {
          ...currentAccessInfo,
          accessCount: currentAccessInfo.accessCount + 1,
        };

        setAccessInfo(newAccessInfo);
        set(ACCESS_INFO_STORAGE_KEY, JSON.stringify(newAccessInfo));
      } catch (error) {
        console.error(error);
      }
    };

    handleAccessInfo();
  }, [get, set]);

  const isExperimentEnabled = useMemo(
    () =>
      checkExperimentEnabled({
        variantName: variant?.name || '',
        accessInfo,
      }),
    [variant, accessInfo]
  );

  const persistAccessInfo = useCallback(() => {
    const newAccessInfo = {
      accessCount: 0,
      lastShownAt: Date.now(),
    };
    setAccessInfo(newAccessInfo);
    set(ACCESS_INFO_STORAGE_KEY, JSON.stringify(newAccessInfo));
  }, [set]);

  return {
    isExperimentEnabled,
    persistAccessInfo,
  };
};
