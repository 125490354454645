import { differenceInDays } from 'date-fns';

export type AccessInfo = {
  accessCount: number;
  lastShownAt: number;
};

type Params = {
  variantName: string;
  accessInfo: AccessInfo;
};

export const checkExperimentEnabled = ({ variantName, accessInfo }: Params) => {
  const { accessCount, lastShownAt } = accessInfo;

  // Check if the variant is valid
  // The variant name should start with 'variant' and followed by a number
  if (!variantName.startsWith('variant-')) {
    return false;
  }

  const variantFrequencyValue = parseInt(
    variantName.replace('variant-', ''),
    10
  );

  const diffInDays = differenceInDays(new Date(), new Date(lastShownAt));

  return diffInDays >= 1 && accessCount >= variantFrequencyValue;
};
