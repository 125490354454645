import { useEffect } from 'react';
import useUser from '@/hooks/useUser';
import loadable from '@loadable/component';
import { useUserAccess } from '@/hooks/useUserAccess';
import { shallowEqual, useSelector } from 'react-redux';
import { useModalManagement } from '@/components/ModalManagement';
import type { Components } from '@/reducers/page';
import { useFrequencyAccessExperiment } from './useFrequencyAccessExperiment';

const PlansModal = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@/components/OurPlans/components/PlansModal/PlansModal'
    ),
  { ssr: false }
);

const EXPERIMENT_PAGES = ['DashboardContainer', 'CompanyReport'];
const EXPERIMENT_PLANS = ['free'];
const MODAL_ID = 'upgradePlansModal';

const UpgradePlansModal = () => {
  const { data: access } = useUserAccess();
  const { data: user, isFetched } = useUser();
  const currentPage = useSelector<
    { page: { current: Components[keyof Components] } },
    Components[keyof Components]
  >((state) => {
    return state.page.current;
  }, shallowEqual);

  const { isShowingModal, addModalIfEmpty, removeModal } =
    useModalManagement(MODAL_ID);

  const { isExperimentEnabled, persistAccessInfo } =
    useFrequencyAccessExperiment();

  useEffect(() => {
    const isSupportedPage = EXPERIMENT_PAGES.includes(currentPage);
    if (
      isFetched &&
      isSupportedPage &&
      user.loggedIn &&
      isExperimentEnabled &&
      EXPERIMENT_PLANS.includes(access.planType) &&
      RUNTIME_ENV !== 'server'
    ) {
      addModalIfEmpty();
      persistAccessInfo();
    }
  }, [
    access.planType,
    addModalIfEmpty,
    currentPage,
    isFetched,
    isExperimentEnabled,
    user.loggedIn,
    persistAccessInfo,
  ]);

  const removeUpgradeModal = () => {
    removeModal();
  };

  return (
    <>
      {isShowingModal && (
        <PlansModal
          onClose={removeUpgradeModal}
          onAction={removeUpgradeModal}
          customTrackEvent={{
            modifier: 'consistent_plans_modal',
          }}
        >
          <p>You’re now on our {access.planType} plan.</p>
          <h2>Select the plan that best suits your needs to continue.</h2>
        </PlansModal>
      )}
    </>
  );
};
export default UpgradePlansModal;
